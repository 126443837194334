import React from "react";
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import favicon from '../images/favicon.ico';

// components
import Navbar from '../components/navbar/navbar';
import Footer from '../components/footer/footer';
import ContactSection from "../components/contact/contact";


function IntegritetOchPolicy() {
    return (
        <Layout>
            <Helmet>
                <title>Excendo Redovisning AB</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta property="og:title" content="Excendo Redovisning AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="" />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="sv_SE" />
                <meta property="og:url" content="http://www.excendo.se/" />
                <link rel="cannonical" href="http://www.excendo.se/" />
            </Helmet>

            <Navbar whitemode={true} />

            <section className="-mt-20">
                {/*-------------- hero section - START ---------------*/}
                <div className="w-screen bg-policy-hero bg-center bg-no-repeat bg-cover">
                    <div className="custome_overlay flex justify-center items-center py-48">
                        <h1 className="sm:text-xl md:text-4xl lg:text-4xl font-semibold text-white text-center">Integritets & personuppgiftspolicy</h1>
                    </div>
                </div>
                {/*-------------- hero section - END ---------------*/}
            </section>



            <section>
                {/*-------------- text section - START ---------------*/}
                <div className="container mx-auto px-4 xl:px-24 my-24">
                    <div className="flex flex-col lg:flex-row items-start justify-start">
                        <div className="w-full flex flex-col lg:items-start pr-0 lg:pr-10">
                            {/*-------------- text huvuddel - START ---------------*/}
                            <div className="text-start mb-3">
                                <span className="font-semibold text-blue-excendo py-1 px-4 rounded-full shadow-md bg-gray-50">Integritets & personuppgiftspolicy</span>
                            </div>
                            <h2 className="text-left text-xl md:text-2xl lg:text-4xl font-bold text-black-excendo mt-10 filter drop-shadow-md">Excendos Integritets och personuppgiftspolicy</h2>
                            <p className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full">
                                Hos Excendo värnar vi om din personliga integritet och eftersträvar alltid en hög nivå av dataskydd. Denna integritetspolicy förklarar hur vi samlar in och använder din personliga information. Vi använder bara personuppgifter till de ändamål vi samlar in dem för och bara så länge det är nödvändigt för ändamålet.
                            </p>
                            <p className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full mb-5">
                                Den beskriver också dina rättigheter och hur du kan göra dem gällande. Det är viktigt att du tar del av och förstår integritetspolicyn och känner dig trygg i vår behandling av dina personuppgifter. Du är alltid välkommen att kontakta oss vid eventuella frågor på info@excendo.se eller via vår hemsida www.excendo.se
                            </p>
                            {/*-------------- text huvuddel - End ---------------*/}

                            {/*-------------- text del 1 - START ---------------*/}
                            <h2 className="text-left text-xl md:text-2xl lg:text-4xl font-bold text-black-excendo mt-10 filter drop-shadow-md">1. Vad är en personuppgift och vad är en behandling av personuppgifter? </h2>
                            <p className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full">
                                Personuppgifter är all slags information som direkt eller indirekt kan hänföras till en fysisk person som är i livet. Till exempel kan bilder och ljudupptagningar som behandlas i dator vara personuppgifter även om inga namn nämns. Krypterade uppgifter och olika slags elektroniska identiteter (till exempel IP-nummer) är personuppgifter ifall de kan kopplas till fysiska personer. Behandling av personuppgifter är allt som sker med personuppgifterna. Varje åtgärd som vidtas med personuppgifter utgör en behandling, oberoende av om den utförs automatiserat eller inte. Exempel på vanliga behandlingar är insamling, registrering, organisering, strukturering, lagring, bearbetning, överföring och radering.
                            </p>
                            <p className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full">
                                I GPDR benämns individer vars personuppgifter behandlas för registrerade.
                            </p>
                            <p className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full">
                                Som registrerad har du rätt att:
                            </p>
                            <ul className="lg:pl-14 mb-5 md:pl-28 ml-4">
                                <li className="list-disc">
                                    <p className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full">
                                        veta om vi har personuppgifter om dig, och i så fall vilka.
                                    </p>
                                </li>
                                <li className="list-disc">
                                    <p className="text-gray-excendo xl:text-lg text-left sm:w-3/4 lg:w-full">
                                        begära rättelse, överföring av uppgifter som du har lämnat till oss eller att vi begränsar behandlingen.
                                    </p>
                                </li>
                                <li className="list-disc">
                                    <p className="text-gray-excendo xl:text-lg text-left sm:w-3/4 lg:w-full">
                                        i vissa fall få dina personuppgifter raderade. Får vi en sådan begäran prövar vi den enligt gällande lagstiftning.
                                    </p>
                                </li>
                            </ul>
                            {/*-------------- text del 1 - End ---------------*/}

                            {/*-------------- text del 2 - START ---------------*/}
                            <h2 className="text-left text-xl md:text-2xl lg:text-4xl font-bold text-black-excendo mt-10 filter drop-shadow-md">2. Vem är ansvarig för de personuppgifter vi samlar in?</h2>
                            <p className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full mb-5">
                                Excendo Redovisning AB, 556777-2560, Box 2040, 151 02 Södertälje samt Excendo Ekonomi & Redovisning AB, 556611-5431, Ellipsvägen 5, 4tr, 141 75 Kungens Kurva är personuppgiftsansvarig för företagets behandling av personuppgifter.
                            </p>
                            {/*-------------- text del 2 - End ---------------*/}

                            {/*-------------- text del 3 - START ---------------*/}
                            <h2 className="text-left text-xl md:text-2xl lg:text-4xl font-bold text-black-excendo mt-10 filter drop-shadow-md">3. Vilka personuppgifter samlar vi in?</h2>
                            <p className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full">
                                Vi behandlar personuppgifter utifrån kundrelationer. Vi samlar in information om dig som du själv direkt eller indirekt lämnar till oss. Det kan exempelvis vara i samband med en intresseanmälan eller en ansökan, när du ingår ett avtal med oss i samband med att du köper en kurs eller i övrigt när vi administrerar ett avtal. Dessutom kan vi spara information från när du besöker våra webbplatser. Det kan till exempel vara information om vilka sidor du besöker, din geografiska placering och hur länge du stannar på våra webbplatser. För det ändamålet använder vi tredjeparts-cookies. Läs mer om användning av cookies under p. 9 i detta dokument.
                            </p>
                            <p className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full">
                                Det finns olika syften för respektive personkategori när det gäller behandling av personuppgifter. För dig som registrerad ska det framgå tydligt varför vi har dina personuppgifter och även hur länge vi sparar dem. Det krävs stöd i GDPR och Dataskyddslagen för att behandla personuppgifter. De personuppgifter som vi samlar in om dig som registrerad är för att kunna upprätthålla ett avtal, en överenskommelse eller för att följa lagstiftningen. Vi sparar inte personuppgifter längre än vad som krävs. Nedan beskrivs varför vi har uppgifter för varje personkategori och hur länge vi sparar personuppgifter.
                            </p>
                            <p className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full mb-5">
                                När du har haft en kundrelation hos oss så behöver vi spara dina uppgifter för att fullfölja avtal och enligt berättigat intresse. Det kommer vi att göra i 10 år.
                            </p>
                            {/*-------------- text del 3 - END ---------------*/}

                            {/*-------------- text del 4 - START ---------------*/}
                            <h2 className="text-left text-xl md:text-2xl lg:text-4xl font-bold text-black-excendo mt-10 filter drop-shadow-md">4. Från vilka källor hämtar vi dina personuppgifter?</h2>
                            <p className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full">
                                Utöver de uppgifter du själv lämnar till oss, eller som vi samlar in från dig utifrån dina köp och hur du använder våra tjänster, kan vi också komma att samla in personuppgifter från någon annan, så kallad tredje part. De uppgifter vi samlar in från tredje part är följande:
                            </p>
                            <p className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full">
                                1- Adressuppgifter från offentliga register för att vara säkra på att vi har rätt adressuppgifter till dig.
                            </p>
                            <p className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full mb-5">
                                2- Uppgifter om kreditvärdighet från kreditvärderingsinstitut, banker eller upplysningsföretag.
                            </p>
                            {/*-------------- text del 4 - END ---------------*/}

                            {/*-------------- text del 5 - START ---------------*/}
                            <h2 className="text-left text-xl md:text-2xl lg:text-4xl font-bold text-black-excendo mt-10 filter drop-shadow-md">5. Vilka kan vi komma att dela dina personuppgifter med?</h2>
                            <p className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full">
                                I de fall det är nödvändigt för att vi ska kunna erbjuda våra tjänster delar vi dina personuppgifter med företag som är så kallade personuppgiftsbiträden för oss. Ett personuppgiftsbiträde är ett företag som behandlar informationen för vår räkning och enligt våra instruktioner. Vi har personuppgiftsbiträden som hjälper oss med:
                            </p>
                            <p className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full">
                                IT-tjänster – företag som hanterar nödvändig drift, teknisk support och underhåll av våra IT-lösningar.
                            </p>
                            <p className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full">
                                Marknadsföring – print och distribution, mediebyråer eller reklambyråer.
                            </p>
                            <p className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full">
                                Betallösningar – kortinlösande företag, banker och andra betaltjänstleverantörer
                            </p>
                            <p className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full">
                                När dina personuppgifter delas med personuppgiftsbiträden sker det endast för ändamål som är förenliga med de ändamål för vilka vi har samlat in informationen (t.ex. för att kunna uppfylla våra åtaganden gentemot Er som uppdragsgivare). Vi kontrollerar alla personuppgiftsbiträden för att säkerställa att de kan lämna tillräckliga garantier avseende säkerheten och sekretessen för personuppgifter. Vi har skriftliga avtal med alla personuppgiftsbiträden genom vilka de garanterar säkerheten för de personuppgifter som behandlas och åtar sig att följa våra säkerhetskrav samt begränsningar och krav avseende internationell överföring av personuppgifter.
                            </p>
                            <h3 className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full font-bold">Företag som är självständigt personuppgiftsansvariga</h3>
                            <p className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full">
                                Vi kan även komma att dela dina personuppgifter med vissa företag som är självständigt personuppgiftsansvariga. Att företaget är självständigt personuppgiftsansvarig innebär att det inte är vi som styr hur informationen som lämnas till företaget ska behandlas. Självständiga personuppgiftsansvariga som vi delar dina personuppgifter med är bl. a:
                            </p>
                            <p className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full">
                                1- Statliga myndigheter såsom polisen, skatteverket eller andra myndigheter, i de fall vi är skyldiga att göra det enligt lag eller vid misstanke om brott.
                            </p>
                            <p className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full mb-5">
                                2- Företag som erbjuder betallösningar som kortinlösande företag, banker och andra betaltjänstleverantörer. När dina personuppgifter delas med ett företag som är självständigt personuppgiftsansvarig gäller det företagets integritetspolicy och personuppgiftshantering.
                            </p>
                            {/*-------------- text del 5 - END ---------------*/}

                            {/*-------------- text del 6 - START ---------------*/}
                            <h2 className="text-left text-xl md:text-2xl lg:text-4xl font-bold text-black-excendo mt-10 filter drop-shadow-md">6. Var behandlar vi dina personuppgifter?</h2>
                            <p className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full mb-5">
                                Vi strävar alltid efter att dina personuppgifter ska behandlas inom EU/EES och alla våra egna IT-system finns inom EU/EES. Vid systemmässig support och underhåll kan vi dock vara tvungna att överföra informationen till ett land utanför EU/EES, till exempel om vi delar dina personuppgifter med ett personuppgiftsbiträde som, antingen själv eller genom en underleverantör, är etablerad eller lagrar information i ett land utanför EU/EES. Biträdet får i dessa fall endast ta del av den information som är relevant för ändamålet som exempelvis loggfiler. Oavsett i vilket land dina personuppgifter behandlas vidtar vi alla rimliga legala, tekniska och organisatoriska åtgärder för att säkerställa att skyddsnivån är densamma som inom EU/EES. I de fall personuppgifter behandlas utanför EU/EES garanteras skyddsnivån antingen genom ett beslut från EU-kommissionen om att landet ifråga säkerställer en adekvat skyddsnivå eller genom användandet av så kallade lämpliga skyddsåtgärder. Exempel på lämpliga skyddsåtgärder är godkänd uppförandekod i mottagarlandet, standardavtalsklausuler, bindande företagsinterna regler eller Privacy Shield. Om du vill få en kopia av de skyddsåtgärder som har vidtagits eller information om var dessa har gjorts tillgängliga är du välkommen att kontakta oss.
                            </p>
                            {/*-------------- text del 6 - END ---------------*/}

                            {/*-------------- text del 7 - START ---------------*/}
                            <h2 className="text-left text-xl md:text-2xl lg:text-4xl font-bold text-black-excendo mt-10 filter drop-shadow-md">7. Hur länge sparar vi dina personuppgifter?</h2>
                            <p className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full">
                                Vi sparar aldrig dina personuppgifter längre än vad som är nödvändigt för respektive ändamål. Se mer om de specifika lagringsperioderna under respektive ändamål. Vad har du för rättigheter som registrerad? Rätt till tillgång, så kallade registerutdrag. Vi är alltid öppna och transparenta med hur vi behandlar dina personuppgifter och om du i förekommande fall behöver få djupare insikt kring vilka personuppgifter vi behandlar om just dig kan du begära att få tillgång till uppgifterna. Informationen lämnas i form av ett registerutdrag med angivande av ändamål, kategorier av personuppgifter, kategorier av mottagare, lagringsperioder, information om varifrån informationen har samlats in och förekomsten av automatiserat beslutsfattande. Tänk på att i de fall vi mottar en begäran om tillgång kan vi komma att fråga om ytterligare uppgifter för att säkerställa en effektiv hantering av din begäran och att informationen lämnas till rätt person.
                            </p>
                            <h3 className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full font-bold">Rätt till rättelse</h3>
                            <p className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full">
                                Du kan begära att dina personuppgifter rättas ifall uppgifterna är felaktiga. Inom ramen för det angivna ändamålet har du också rätt att komplettera eventuellt ofullständiga personuppgifter.
                            </p>
                            <h3 className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full font-bold">Rätt till radering</h3>
                            <p className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full">
                                Du kan begära radering av personuppgifter vi behandlar om dig i de fall:
                            </p>
                            <ul className="lg:pl-14 mb-5 md:pl-28 ml-4">
                                <li className="list-disc">
                                    <p className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full">
                                        Uppgifterna inte längre är nödvändiga för de ändamål för vilka de har samlats in eller behandlats.
                                    </p>
                                </li>
                                <li className="list-disc">
                                    <p className="text-gray-excendo xl:text-lg text-left sm:w-3/4 lg:w-full">
                                        Du invänder mot en intresseavvägning vi har gjort baserat på berättigat intresse och ditt skäl för invändning väger tyngre än vårt berättigade intresse.
                                    </p>
                                </li>
                                <li className="list-disc">
                                    <p className="text-gray-excendo xl:text-lg text-left sm:w-3/4 lg:w-full">
                                        Du invänder mot behandling för direktmarknadsföringsändamål.
                                    </p>
                                </li>
                                <li className="list-disc">
                                    <p className="text-gray-excendo xl:text-lg text-left sm:w-3/4 lg:w-full">
                                        Personuppgifterna behandlas på ett olagligt sätt.
                                    </p>
                                </li>
                                <li className="list-disc">
                                    <p className="text-gray-excendo xl:text-lg text-left sm:w-3/4 lg:w-full">
                                        Personuppgifterna måste raderas för att uppfylla en rättslig förpliktelse vi omfattas av.
                                    </p>
                                </li>
                            </ul>
                            <p className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full">
                                Tänk också på att vi kan ha rätt att neka din begäran i de fall det finns legala skyldigheter som hindrar oss från att omedelbart radera vissa personuppgifter. Dessa skyldigheter kommer från bokförings- och skattelagstiftningen, bank- och penningtvättslagstiftningen samt från konsumenträttslagstiftningen.
                            </p>
                            <p className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full">
                                Det kan också hända att behandlingen är nödvändig för att vi ska kunna fastställa, göra gällande eller försvara rättsliga anspråk. Skulle vi vara förhindrade att tillmötesgå en begäran om radering kommer vi istället att blockera personuppgifterna från att kunna användas till andra syften än det syfte som hindrar den begärda raderingen.
                            </p>
                            <h3 className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full font-bold">Rätt till begränsning</h3>
                            <p className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full">
                                Du har rätt att begära att vår behandling av dina personuppgifter begränsas. Om du bestrider att personuppgifterna vi behandlar är korrekta kan du begära en begränsad behandling under den tid vi behöver för att kontrollera huruvida personuppgifterna är korrekta. Om vi inte längre behöver personuppgifterna för de fastställda ändamålen, men du däremot behöver dem för att kunna fastställa, göra gällande eller försvara rättsliga anspråk, kan du begära begränsad behandling av uppgifterna hos oss. Det innebär att du kan begära att vi inte raderar dina uppgifter.
                            </p>
                            <p className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full">
                                Om du har invänt mot en intresseavvägning av berättigat intresse som vi har gjort som laglig grund för ett ändamål kan du begära begränsad behandling under den tid vi behöver för att kontrollera huruvida våra berättigade intressen väger tyngre än dina intressen av att få uppgifterna raderade. Om behandlingen har begränsats enligt någon av situationerna ovan får vi bara, utöver själva lagringen, behandla uppgifterna för att fastställa, göra gällande eller försvara rättsliga anspråk, för att skydda någon annans rättigheter eller ifall du har lämnat ditt samtycke.
                            </p>
                            <h3 className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full font-bold">Rätt att göra invändningar mot viss typ av behandling</h3>
                            <p className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full">
                                Du har alltid rätt att slippa direktmarknadsföring och att invända mot all behandling av personuppgifter som bygger på en intresseavvägning.
                            </p>
                            <p className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full">
                                Berättigat intresse: I de fall vi använder en intresseavvägning som laglig grund för ett ändamål har du möjlighet att invända mot behandlingen. För att kunna fortsätta behandla dina personuppgifter efter en sådan invändning behöver vi kunna visa ett tvingande berättigat skäl för den aktuella behandlingen som väger tyngre än dina intressen, rättigheter eller friheter. I annat fall får vi bara behandla uppgifterna för att fastställa, utöva eller försvara rättsliga anspråk.
                            </p>
                            <p className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full">
                                Direktmarknadsföring (inklusive analyser som utförs för direktmarknadsföringsändamål): Du har möjlighet att invända mot att dina personuppgifter behandlas för direktmarknadsföring. Invändningen omfattar även de analyser av personuppgifter, så kallad profilering, som utförs för direktmarknadsföringsändamål. Med direktmarknadsföring avses alla typer av uppsökande marknadsföringsåtgärder till exempel via e-post, Google mm. Marknadsföringsåtgärder där du som kund aktivt valt att använda en av våra tjänster eller annars sökt upp oss för att få veta mer om våra tjänster räknas inte som direktmarknadsföring exempelvis produktrekommendationer eller andra funktioner. Om du invänder mot direktmarknadsföring kommer vi att upphöra med behandlingen av dina personuppgifter för det ändamålet liksom upphöra med alla typer av direktmarknadsföringsåtgärder.
                            </p>
                            <p className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full">
                                Tänk på att du alltid har möjlighet att avregistrera dig genom att kontakta oss.
                            </p>
                            <h3 className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full font-bold">Rätt till dataportabilitet</h3>
                            <p className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full mb-5">
                                Om vår rätt att behandla dina personuppgifter grundar sig antingen på ditt samtycke eller fullgörande av ett avtal med dig har du rätt att begära att få de uppgifter som rör dig och som du har lämnat till oss överförda till en annan personuppgiftsansvarig, så kallad dataportabilitet. En förutsättning för dataportabilitet är att överföringen är tekniskt möjlig och kan ske automatiserad.
                            </p>
                            {/*-------------- text del 7 - END ---------------*/}

                            {/*-------------- text del 8 - START ---------------*/}
                            <h2 className="text-left text-xl md:text-2xl lg:text-4xl font-bold text-black-excendo mt-10 filter drop-shadow-md">8. Hur hanterar vi personnummer?</h2>
                            <p className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full mb-5">
                                Vi kommer bara att behandla ditt personnummer när det är klart motiverat med hänsyn till ändamålet, nödvändigt för säker identifiering eller om det finns något annat beaktansvärt skäl.
                            </p>
                            {/*-------------- text del 8 - END ---------------*/}

                            {/*-------------- text del 9 - START ---------------*/}
                            <h2 className="text-left text-xl md:text-2xl lg:text-4xl font-bold text-black-excendo mt-10 filter drop-shadow-md">9. Vad är cookies och hur använder vi det?</h2>
                            <p className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full">
                                Cookies är en liten textfil bestående av bokstäver och siffror som skickas från vår webbserver och sparas på din webbläsare eller enhet. På våra webbplatser använder vi följande cookies:
                            </p>
                            <ul className="lg:pl-14 mb-5 md:pl-28 ml-4">
                                <li className="list-decimal">
                                    <p className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full">
                                        Tredjepartscookies - Cookies som satts av en tredjeparts webbplats. Hos oss används dessa i första hand för analyser, som exempelvis Google Analytics och chatfunktion.
                                    </p>
                                </li>
                                <li className="list-decimal">
                                    <p className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full">
                                        Liknande tekniker - Tekniker som lagrar information i din webbläsare eller i din enhet på ett sätt som liknar cookies.
                                    </p>
                                </li>
                            </ul>
                            <p className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full mb-5">
                                De cookies vi använder syftar till att förbättra de tjänster vi erbjuder. Några av våra webbtjänster behöver cookies för att fungera korrekt. Vi använder cookies för övergripande analytisk information avseende din användning av våra tjänster och för att spara funktionella inställningar.</p>
                            {/*-------------- text del 9 - END ---------------*/}

                            {/*-------------- text del 10 - START ---------------*/}
                            <h2 className="text-left text-xl md:text-2xl lg:text-4xl font-bold text-black-excendo mt-10 filter drop-shadow-md">10. Kan du själv styra användningen av cookies?</h2>
                            <p className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full mb-5">
                                Ja! Din webbläsare eller enhet ger dig möjlighet att ändra inställningarna för användningen och omfattningen av cookies. Gå till inställningarna för din webbläsare eller enhet för att lära dig mer om hur du själv justerar inställningarna för cookies. Exempel på sådant du kan justera är blockering av alla cookies, endast acceptera förstapartscookies eller radera cookies när du stänger ner din webbläsare. Ha i åtanke att vissa tjänster kanske inte fungerar ifall du blockerar eller raderar cookies. Du kan läsa mer om cookies generellt på post- och telestyrelsens webbplats, www.pts.se
                            </p>
                            {/*-------------- text del 10 - END ---------------*/}

                            {/*-------------- text del 11 - START ---------------*/}
                            <h2 className="text-left text-xl md:text-2xl lg:text-4xl font-bold text-black-excendo mt-10 filter drop-shadow-md">11. Hur skyddas dina personuppgifter?</h2>
                            <p className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full mb-5">
                                Vi använder IT-system för att skydda sekretessen, integriteten och tillgången till personuppgifter. Vi har vidtagit särskilda säkerhetsåtgärder för att skydda dina personuppgifter mot olovlig eller obehörig behandling såsom olovlig tillgång, förlust, förstörelse eller skada. Endast de personer som faktiskt behöver behandla dina personuppgifter för att vi ska kunna uppfylla våra angivna ändamål har tillgång till dem.
                            </p>
                            {/*-------------- text del 11 - END ---------------*/}

                            {/*-------------- text del 12 - START ---------------*/}
                            <h2 className="text-left text-xl md:text-2xl lg:text-4xl font-bold text-black-excendo mt-10 filter drop-shadow-md">12. Vad innebär det att Datainspektionen är tillsynsmyndighet?</h2>
                            <p className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full mb-5">
                                Datainspektionen/ Dataskyddsmyndigheten  är ansvarig för att övervaka tillämpningen av lagstiftningen och den som anser att ett företag hanterar personuppgifter på ett felaktigt sätt kan lämna in ett klagomål till Datainspektionen/ Dataskyddsmyndigheten.
                            </p>
                            {/*-------------- text del 12 - END ---------------*/}

                            {/*-------------- text del 13 - START ---------------*/}
                            <h2 className="text-left text-xl md:text-2xl lg:text-4xl font-bold text-black-excendo mt-10 filter drop-shadow-md"> 13. Hur informeras du om ändringar och uppdateringar i integritetspolicyn?</h2>
                            <p className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full mb-5">
                                Vi kan komma att göra ändringar i vår integritetspolicy. Den senaste versionen av integritetspolicyn finns alltid på www.excendo.se. Vid uppdateringar som är av avgörande betydelse för vår behandling av personuppgifter eller uppdateringar som inte är av avgörande betydelse för behandlingen, men som kan vara av avgörande betydelse för dig. Det kan exempelvis gälla ändring av angivna ändamål eller kategorier av personuppgifter. I dessa fall kommer du att informeras via e-post (i de fall du har angivit e-post) i god tid innan uppdateringarna börjar gälla. När vi tillgängliggör information om uppdateringar kommer vi även att förklara innebörden av uppdateringarna och hur de kan påverka dig.
                            </p>
                            {/*-------------- text del 13 - END ---------------*/}
                        </div>
                    </div>
                </div>
                {/*-------------- text section - END ---------------*/}
            </section>


            <ContactSection/>
            <Footer/>
        </Layout>
    );
}

export default IntegritetOchPolicy;